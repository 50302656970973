interface IErrorReporting {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error(error: any, data?: any): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  info(error: any, data?: any): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  warn(error: any, data?: any): void;
  setUser(user: { id: string }): void;
  removeUser(): void;
}

/**
 * Mocks class
 */
class ErrorReportingMocks implements IErrorReporting {
  // eslint-disable-next-line class-methods-use-this, no-empty-function
  setUser() {}

  // eslint-disable-next-line class-methods-use-this, no-empty-function
  removeUser() {}

  // eslint-disable-next-line class-methods-use-this, no-empty-function
  info() {}

  // eslint-disable-next-line class-methods-use-this, no-empty-function
  warn() {}

  // eslint-disable-next-line class-methods-use-this, no-empty-function
  error() {}
}

const errorReporting: IErrorReporting = new ErrorReportingMocks();

export default errorReporting;
